import { Box, Flex, Spinner, Stack, Text } from '@chakra-ui/core';
import React, { useEffect, useState, useReducer } from 'react';
import { bodyLarge, h2 } from '../client/constants/typography';
import { useStoreActions, useStoreState } from '../client/redux/hooks';

import MenuBar from '../client/components/MenuBar';
import { Prospect } from '../_generated/types';
import SearchResult from '../client/components/SearchResult';
import _ from 'lodash';
import moment from 'moment';
import withRouteAuthorization from '../client/hoc/withRouteAuthorization';
import InfoModal from '../client/components/Modals/InfoModal';
import strings from '../client/constants/strings';
import Button from '../client/components/Common/Button';
import { DeleteStatus, ReviseStatus } from '../client/constants/enums';
import {
  GetProspectDocument,
  GetProspectQuery,
  useDeleteTourMapMutation,
  useUpdateTourMapMutation,
} from '../client/graphql/graphql';
import SearchBar from '../client/components/Common/Input/SearchBar';
import cloneWithoutTypename from '../client/utils/cloneWithoutTypename';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import * as RA from 'ramda-adjunct';
import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { deepClone } from '../client/utils';

interface InitialStateProps {
  deleting: boolean;
  deleteSuccess: number;
  revising: boolean;
  reviseSuccess: number;
}

type ActionType = {
  type:
    | 'start_deleting'
    | 'done_deleting'
    | 'delete_unstarted'
    | 'deleted'
    | 'delete_error'
    | 'start_revising'
    | 'done_revising'
    | 'revise_unstarted'
    | 'revised'
    | 'revise_error';
};

const reducer = (state: InitialStateProps, action: ActionType) => {
  switch (action.type) {
    case 'start_deleting':
      return {
        ...state,
        deleting: true,
      };
    case 'done_deleting':
      return {
        ...state,
        deleting: false,
      };
    case 'delete_unstarted':
      return {
        ...state,
        deleteSuccess: DeleteStatus.UNSTARTED,
      };
    case 'deleted':
      return {
        ...state,
        deleteSuccess: DeleteStatus.DELETED,
      };
    case 'delete_error':
      return {
        ...state,
        deleteSuccess: DeleteStatus.ERROR,
      };
    case 'start_revising':
      return {
        ...state,
        revising: true,
      };
    case 'done_revising':
      return {
        ...state,
        revising: false,
      };
    case 'revise_unstarted':
      return {
        ...state,
        reviseSuccess: ReviseStatus.UNSTARTED,
      };
    case 'revised':
      return {
        ...state,
        reviseSuccess: ReviseStatus.REVISED,
      };
    case 'revise_error':
      return {
        ...state,
        reviseSuccess: ReviseStatus.ERROR,
      };
  }
};

const Prospects = () => {
  const router = useRouter();
  const [result, setResult] = useState<Prospect[] | null>(null);
  const email = useStoreState(({ prospect }) => prospect.email);
  const setSearched = useStoreActions(({ prospect }) => prospect.setSearched);
  const setEmail = useStoreActions(({ prospect }) => prospect.setEmail);
  const startTime = moment().format('YYYY-MM-DD').toString();
  const { getAccessTokenSilently } = useAuth0();

  const [executeQuery, { loading, error, data, refetch, called }] = useLazyQuery<GetProspectQuery>(
    GetProspectDocument,
    {
      variables: {
        email: router.query.prospectEmail,
        startTime,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const prospectData = data?.getProspectByEmail;
        if (prospectData) {
          // The objects will be modified later, this is why deep clone is needed in order to make some properties mutable
          const prospects = prospectData as Prospect[];
          const clonedProspects = deepClone(prospects);
          setSearched(clonedProspects);
        }
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const { handleSubmit, register, reset, getValues } = useForm<{
    prospectEmail: string;
  }>({ defaultValues: { prospectEmail: email } });

  const initialState: InitialStateProps = {
    deleting: false,
    deleteSuccess: DeleteStatus.UNSTARTED,
    revising: false,
    reviseSuccess: ReviseStatus.UNSTARTED,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Delete map state
  const tourIdToDelete = useStoreState(({ prospect }) => prospect.tourIdToDelete);
  const setIdToDelete = useStoreActions(({ prospect }) => prospect.setTourId);
  const [deleteTourMap] = useDeleteTourMapMutation();

  // Revise map state
  const tourIdToRevise = useStoreState(({ prospect }) => prospect.tourIdToRevise);
  const setTourIdToRevise = useStoreActions(({ prospect }) => prospect.setTourIdToRevise);
  const [updateMap] = useUpdateTourMapMutation();

  useEffect(() => {
    const email = router.query.prospectEmail as string;

    reset({ prospectEmail: email });

    if (RA.isNotNilOrEmpty(email)) {
      executeQuery({ variables: { email, startTime } }).then();
    }
  }, [router.query.prospectEmail]);

  useEffect(() => {
    const prospectData = data?.getProspectByEmail;
    if (prospectData) {
      // The objects will be modified later, this is why deep clone is needed in order to make some properties mutable
      const prospects = prospectData as Prospect[];
      const clonedProspects = deepClone(prospects) as Prospect[];
      setResult(clonedProspects);
    } else {
      setResult(null);
    }
  }, [data]);

  useEffect(() => {
    if (email) {
      executeQuery({ variables: { email, startTime } }).then();
    }
  }, [email]);

  const handleCloseModal = () => {
    setIdToDelete('');
    setTourIdToRevise('');
  };

  const handleDelete = async () => {
    dispatch({ type: 'start_deleting' });
    const { data } = await deleteTourMap({ variables: { input: { tourId: tourIdToDelete } } });
    if (data?.deleteTourMap?.id) {
      dispatch({ type: 'deleted' });
      setTimeout(() => {
        setIdToDelete('');
        dispatch({ type: 'delete_unstarted' });
      }, 2000); // Close after 2 seconds
    } else {
      dispatch({ type: 'delete_error' });
    }
    dispatch({ type: 'done_deleting' });
    // Refresh the page after deleting to update displayed tour info
    handleRefresh();
  };

  const handleRevise = async () => {
    dispatch({ type: 'start_revising' });
    const { data } = await updateMap({
      variables: {
        input: cloneWithoutTypename({
          tourId: tourIdToRevise,
          completedAt: null,
        }),
      },
    });
    // const isMapsPeople = data?.updateTourMap?.metadata.isMapsPeople;
    if (data?.updateTourMap?.id) {
      dispatch({ type: 'revised' });
      refetch?.();
      setTimeout(() => {
        setTourIdToRevise('');
        dispatch({ type: 'revise_unstarted' });
        router.push(`/tour/${tourIdToRevise}`);
      }, 2000);
    } else {
      dispatch({ type: 'revise_error' });
    }
    dispatch({ type: 'done_revising' });
  };

  const onSubmit = handleSubmit(async ({ prospectEmail }) => {
    if (!RA.isNotNilOrEmpty(prospectEmail)) {
      return;
    }
    setEmail(prospectEmail);
    try {
      await getAccessTokenSilently();
    } catch (e) {
      if (e?.error === 'login_required') {
        location.reload();
      }
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('prospectEmail', prospectEmail);
    history.pushState(null, '', window.location.pathname + '?' + searchParams.toString());

    executeQuery({ variables: { email: prospectEmail, startTime } }).then();
  });

  const handleRefresh = () => {
    const prospectEmail = getValues('prospectEmail');
    if (!RA.isNotNilOrEmpty(prospectEmail)) {
      return;
    }
    executeQuery({ variables: { email: prospectEmail, startTime, skipCache: true } }).then();
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <MenuBar />

      <InfoModal
        isOpen={!!tourIdToDelete}
        closeModal={handleCloseModal}
        heading={strings.SearchPage.deleteHeading}
        Footer={
          <>
            <Button
              buttonStyle="large"
              mr="12px"
              _hover={{ bg: 'alertRed', borderColor: 'alertRed', color: 'white' }}
              onClick={handleDelete}
              isLoading={state.deleting}
            >
              {strings.SearchPage.deleteConfirm(state.deleteSuccess)}
            </Button>
            <Button buttonStyle="strong" onClick={handleCloseModal}>
              {strings.SearchPage.deleteCancel}
            </Button>
          </>
        }
      >
        <Text {...bodyLarge}>{strings.SearchPage.deleteBody}</Text>
      </InfoModal>

      <InfoModal
        isOpen={!!tourIdToRevise}
        closeModal={handleCloseModal}
        heading={strings.SearchPage.reviseHeading}
        Footer={
          <>
            <Button buttonStyle="large" onClick={handleCloseModal}>
              {strings.SearchPage.reviseCancel}
            </Button>
            <Button buttonStyle="strong" ml="12px" onClick={handleRevise} isLoading={state.revising}>
              {strings.SearchPage.reviseConfirm(state.reviseSuccess)}
            </Button>
          </>
        }
      >
        <Text {...bodyLarge}>{strings.SearchPage.reviseBody}</Text>
      </InfoModal>

      <Stack
        shouldWrapChildren={true}
        mt="84px"
        spacing={8}
        p={{ base: '12px', md: '48px' }}
        maxW="1200px"
        width="100%"
      >
        <Box display={{ md: 'flex' }} alignItems="center" mb="24px">
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <SearchBar
              data-cy="SearchBar"
              type="email"
              id="searchProspect"
              placeholder="Prospect Email"
              clearInput={() => reset({ prospectEmail: '' })}
              isRequired
              {...register('prospectEmail')}
            />
          </form>
        </Box>

        <Stack spacing={7} letterSpacing=".2rem" textTransform="uppercase">
          {(() => {
            if (error) {
              return (
                <Text data-cy="SearchErrorMessage" as="h2" {...h2}>
                  {strings.SearchPage.searchError}
                </Text>
              );
            } else if (loading) {
              return (
                <Text as="h2" {...h2} display="flex" alignItems="center">
                  <Spinner size="sm" color="primary" mr="10px" />
                  {strings.SearchPage.searchLoading}
                </Text>
              );
            } else {
              return (
                <Flex alignItems="center">
                  {!loading ? (
                    _.isEmpty(result) || !result ? (
                      called && (
                        <Text data-cy="SearchErrorMessage" as="h2" {...h2}>
                          {strings.SearchPage.notFound}
                        </Text>
                      )
                    ) : (
                      <Text as="h2" {...h2}>
                        {strings.SearchPage.found(result.length)}
                      </Text>
                    )
                  ) : null}

                  {result?.length ? (
                    <>
                      <Box h="28px" w="1px" backgroundColor="dividerLine" mx="20px" />
                      <Button buttonStyle="small" onClick={handleRefresh}>
                        Refresh
                      </Button>
                    </>
                  ) : null}
                </Flex>
              );
            }
          })()}
        </Stack>

        {result?.map((prospect, i) => (
          <SearchResult key={prospect.id ?? i} prospect={prospect} />
        ))}
      </Stack>
    </section>
  );
};

export default withRouteAuthorization(Prospects, { loginOptions: { prompt: 'select_account' } });
